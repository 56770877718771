import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Control, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Item } from '@/packages/back-end/jsonapi';
import PatientSchema, {
  DOWNLOAD_PATIENT,
  POST_COLLECTION,
} from '@/packages/back-end/patient';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import Patient from '@/packages/back-end/patient';
import useRequest, { STATUS_CODE } from '@/services/request/useRequest';
import { axiosErrorParser, ConflictError } from '@/services/request/utils';

import { yupResolver } from '@hookform/resolvers/yup';
import ContainerFooter from '@ui/atoms/containers/container-footer/ContainerFooter';
import Button from '@ui/atoms/buttons/button/Button';
import OverflowContainer from '@ui/atoms/containers/overflow-container/OverflowContainer';

import { downloadFileFromUrl } from '@/utils/file';
import useToast from '@/utils/hooks/useToast';
import { buildLegacyUrl, URLS } from '@/utils/Legacy';
import { printDocument } from '@/utils/print';
import { optionTypeValidator } from '@/components/form/utils';
import LinkButton from '@/components/link/LinkButton';
import { onCloseType } from '@/components/drawer/useUpdateDrawer';

import PatientFormFields, {
  PatientFormDataType,
  parsePatientData,
} from './PatientFormFields';
import ConnectionSheet from '../ConnectionSheet';
import useConflictModal from '@/components/conflict-modal/useConflictModal';
import { buildUrl } from '@/utils/Api';
import { SettingsPatientsType } from '@/app/structure/page/staff/profile/patient/Patient';

const schema = yup.object({
  title: optionTypeValidator().required('field_required'),
  firstName: yup.string().required('field_required'),
  lastName: yup.string().required('field_required'),
});

const getDefaultValues = (): Partial<PatientFormDataType> => {
  return {
    sendInvitationEmail: true,
    sendInvitationSMS: true,
    blockAllCommunications: false,
  };
};

type DownloadResponseType = {
  file: string;
};


type FooterProps = {
  isAddFromOutside?: boolean;
  cancelTextKey?: string;
  submitTextKey?: string;
  saving: boolean;
  patient?: PatientSchema | null;
  onCancel?: () => void;
  onSubmit?: () => void;
};

const Footer: FC<FooterProps> = ({
  isAddFromOutside,
  cancelTextKey,
  submitTextKey,
  saving,
  patient,
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation('medical_staff', {
    keyPrefix: 'add_patient.form',
  });

  const [downloadType, setDownloadType] = useState<'print' | 'download' | ''>(
    ''
  );

  const { loading: downloadingEvent, fetchData: downloadPatient } =
    useRequest<DownloadResponseType>({
      skip: true,
      method: 'get',
      url: DOWNLOAD_PATIENT(patient?.attributes?._id),
    });

  const onPrintPatientSheet = async () => {
    setDownloadType('print');
    try {
      const response = (await downloadPatient()) as DownloadResponseType;
      printDocument(response.file);
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setDownloadType('');
    }
  };

  const onDownloadPatientSheet = async () => {
    setDownloadType('download');
    try {
      const response = (await downloadPatient()) as DownloadResponseType;
      const filename = new URL(response.file).pathname?.split('/')?.pop() || '';
      downloadFileFromUrl(
        response.file,
        filename || patient?.attributes?.fullName || ''
      );
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setDownloadType('');
    }
  };

  if (patient) {
    return (
      <ContainerFooter
        className='px-9'
        size='small'
      >
        <Button
          className='flex-grow'
          type='button'
          color='secondary'
          disabled={downloadingEvent && downloadType !== 'download'}
          saving={downloadingEvent && downloadType === 'download'}
          onClick={onDownloadPatientSheet}
        >
          {t('download_sheet_button')}
        </Button>
        <Button
          className='flex-grow'
          type='button'
          color='secondary'
          disabled={downloadingEvent && downloadType !== 'print'}
          saving={downloadingEvent && downloadType === 'print'}
          onClick={onPrintPatientSheet}
        >
          {t('print_button')}
        </Button>
        <LinkButton
          className='flex-grow'
          color='primary'
          href={buildLegacyUrl(URLS.patient.profile, {
            userId: patient?.relationships?.user?.data?.attributes?._id,
          })}
        >
          {t('view_profile_button')}
        </LinkButton>
      </ContainerFooter>
    );
  }

  return (
    <ContainerFooter
      className='px-9'
      size='small'
    >
      <Button
        className='flex-grow'
        type='button'
        color='secondary'
        disabled={saving}
        onClick={onCancel}
      >
        {isAddFromOutside && cancelTextKey ? (
          t(cancelTextKey)
        ) : (
          <Trans i18nKey='ui.button.cancel' />
        )}
      </Button>
      <Button
        className='flex-grow'
        saving={saving}
        type='button'
        color='primary'
        onClick={onSubmit}
      >
        {isAddFromOutside && submitTextKey ? (
          t(submitTextKey)
        ) : (
          <Trans i18nKey={'ui.button.submit'} />
        )}
      </Button>
    </ContainerFooter>
  );
};

type AddPatientFormProps = {
  isAddFromOutside?: boolean;
  cancelTextKey?: string;
  submitTextKey?: string;
  onAdded?: (
    patient: PatientSchema | null,
    options: { hasEmail: boolean }
  ) => void;
  onClose?: () => void;
  onCloseCallback?: onCloseType<Patient>;
};

const AddPatientForm: FC<AddPatientFormProps> = ({
  isAddFromOutside,
  cancelTextKey,
  submitTextKey,
  onAdded,
  onClose,
  onCloseCallback,
}) => {
  const { addToast } = useToast();
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    resetField,
    watch,
    clearErrors,
    setError,
  } = useForm<PatientFormDataType>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    defaultValues: getDefaultValues(),
  });

  const { loading: loadingSettings, data: settingPatients } =
    useJsonAPIRequest<SettingsPatientsType>({
      method: 'get',
      url: buildUrl('settings/patients'),
    });



  const { loading: creatingPatient, fetchData: createPatient } =
    useJsonAPIRequest({
      skip: true,
      method: 'post',
      url: POST_COLLECTION({ include: 'user,user.phone,addesses' }),
    });

  const { t } = useTranslation('medical_staff', {
    keyPrefix: 'add_patient.form',
  });

  const { openModal,modal } = useConflictModal();

  const [patient, setPatient] = useState<PatientSchema | null>(null);

  useEffect(() => {
    if(!settingPatients) {
      return;
    }

    resetField('sendInvitationEmail',{
      defaultValue: settingPatients.sendInvitationEmail
    });
    resetField('sendInvitationSMS',{
      defaultValue: settingPatients.sendInvitationSMS
    });
    resetField('blockAllCommunications',{
      defaultValue: settingPatients.blockAllCommunications
    });
  }, [settingPatients,resetField]);

  const onSubmit = async (data: PatientFormDataType) => {
    try {
      const parsedData = parsePatientData(data);
      const response = (await createPatient(parsedData, {
        throw: true,
      })) as Item<PatientSchema>;

      if (isAddFromOutside) {
        addToast('success', t('add_from_event_success'));
      } else {
        setPatient(response.data || null);
      }
      onAdded?.(response.data || null, { hasEmail: !!parsedData.user.email });
      onCloseCallback?.(true, response);

      return response;
      // eslint-disable-next-line no-empty
    } catch (error) {
      axiosErrorParser(error, setError);

      const e = error as ConflictError;

      // Handle conflicts
      if(e.code === STATUS_CODE.CONFLICT) {
        const field = (e.field as 'email' | 'socialSecurityNumber');

        const callback = () => onSubmit({
          ...data,
          [field]: '',
        });

        setError(field, { message: e.message });
        openModal({
          field,
          value: data[field] as string,
          hasAccess: e.hasAccess,
          object: e.object,
          objectName: e.objectName,
        },callback);
      }

      throw error;

    }
  };

  const sendInvitationEmail = watch('sendInvitationEmail');
  const sendInvitationSMS = watch('sendInvitationSMS');
  const blockAllCommunications = watch('blockAllCommunications');

  const invalid = (sendInvitationEmail || sendInvitationSMS) && blockAllCommunications;

  useEffect(() => {

    if(invalid) {
      setError('blockAllCommunications', {
        type: 'manual',
        message: t('fields.block_all_communications_error')
      });
    } else {
      clearErrors('blockAllCommunications');
    }
  }, [setError,clearErrors,invalid,t]);


  return (
    <div className='flex flex-col h-full'>
      {modal}
      <OverflowContainer className='flex-grow sm:p-8 py-4 px-6'>
        {patient ? (
          <ConnectionSheet patient={patient} />
        ) : (
          <PatientFormFields
            control={control as unknown as Control}
            errors={errors}
            setValue={setValue}
            resetField={resetField}
            loadingPatientSettings={loadingSettings}
            register={register}
            watch={watch}
          />
        )}
      </OverflowContainer>

      <div className='flex-shrink-0'>
        <Footer
          isAddFromOutside={isAddFromOutside}
          cancelTextKey={cancelTextKey}
          submitTextKey={submitTextKey}
          saving={creatingPatient}
          patient={patient}
          onCancel={onClose}
          onSubmit={handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
};

export default AddPatientForm;
