import React, { FC, useState, useCallback, useMemo, ReactNode } from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';

import Header from '@ui/organisms/header/Header';
import { HeaderActionsType } from '@ui/molecules/header/actions/HeaderActions';

import Menu from '@/app/structure/menu/Menu';

import DrawerManager from '@/app/structure/drawer-manager/DrawerManager';

import Institution from '@/packages/back-end/Institution';
import { authAtom } from '@/services/store/store';
import { getUserAvatarInfos } from '@/utils/userAvatar';
import { getRoute, isWebappUrl } from '@/utils/router';
import { Badges, LegacyMe } from '@/packages/types';

import useBadge from '@/utils/hooks/useBadge';
import { isPatient, MeUser } from '@/packages/back-end/user';
import { URL_HELP,URL_HELP_PATIENT } from '@/constants';
import useNavigationBar from '@/utils/hooks/useNavigationBar';
import ImpersonationButton from '@/components/impersonation-button/ImpersonationButton';
import { DiscussionsProvider } from '../page/chat/ChatContext';
import useViewport from '@/utils/hooks/useViewport';
import { EmailsProvider } from '../page/mssante/EmailsContext';
import { useHistory } from 'react-router-dom';

const ContentContainer = styled.div`
  flex: 1 1 auto;
`;

interface AppProps {
  children?: ReactNode;
}

const App: FC<AppProps> = ({ children }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const history = useHistory();

  const { xs } = useViewport();

  const onToggleMenu = useCallback(() => {
    setShowMobileMenu((prevState) => !prevState);
  }, []);

  const { user, legacy_user } = useRecoilValue(authAtom) as {
    user: MeUser;
    legacy_user: LegacyMe;
  };
  const badges = useBadge();

  const { notification, upgradeModal, notifications,...navigation } =
    useNavigationBar({ badges });

  const userInfo = useMemo((): HeaderActionsType['user'] => {
    const avatarProps =
      legacy_user &&
      getUserAvatarInfos({
        user: legacy_user,
        size: 'base',
      });

    const editUrl = user?.attributes?.role
      ? getRoute('editProfile', user?.attributes?.role)
      : null;

    const onClick = isWebappUrl(editUrl) ? () => {
      history.push(editUrl);
    } : undefined;

    const institution = user?.relationships?.institutions?.data
      ?.find((item => (item as Institution).attributes.current)) as Institution | undefined;

    const mobileLogo =
      institution?.attributes?.thumbnails?.mobileLogo?.['30x30'] ?? institution?.attributes?.mobileLogo;

    return {
      ...avatarProps,
      onClick,
      href: editUrl || '/',
      mobileLogo,
    };
  }, [legacy_user,history, user]);

  return (
    <DiscussionsProvider>
      <EmailsProvider>
        <div className='flex relative items-stretch p-0 w-full h-full font-inter'>
          <Menu
            showMobileMenu={showMobileMenu}
            onToggleMenu={onToggleMenu}
            user={legacy_user}
            className='fixed sm:sticky top-0 flex-grow-0 flex-shrink-0'
          />
          <ContentContainer className='flex overflow-hidden flex-col flex-grow sm:mr-[56px] min-h-screen bg-body'>
            {upgradeModal}
            {notification}
            {xs && (
              <Header
                notifications={notifications}
                helpLink={isPatient(user) ? URL_HELP_PATIENT : URL_HELP}
                user={userInfo}
                onNewPatient={navigation.onAddNewPatient}
                onAiDrawer={navigation.onOpenAiDrawer}
                onNewConsultation={navigation.onNewConsultation}
                onMenuClick={onToggleMenu}
              />
            )}
            <div className='flex flex-col flex-grow px-[15px] sm:min-h-[100vh]'>
              <ImpersonationButton />
              {children}
            </div>
          </ContentContainer>
          <DrawerManager user={userInfo} badges={badges as Badges} />
        </div>
      </EmailsProvider>
    </DiscussionsProvider>
  );
};

export default App;
