import React from 'react';
import Separator from 'instamed-styleguide/ui/atoms/containers/separator/Separator';
import styled from 'styled-components';
import { formatDate } from '@/components/date-format/dateFormat';
import Icon from 'instamed-styleguide/ui/atoms/icons/icon/Icon';
import { useDiscussions } from '../ChatContext';
import Message from '@/packages/back-end/message';
import { useRecoilValue } from 'recoil';
import { authAtom } from '@/services/store/store';
import SpinnerLoader from '@ui/atoms/loaders/spinner/SpinnerLoader';
import { useTranslation } from 'react-i18next';

export const TimeParagraph = ({
  createdDate,
  className,
  loading,
  isMyMessage,
}: {
  loading?: boolean,
  createdDate: string;
  className: string;
  isMyMessage: boolean;
}) => {

  const { user } = useRecoilValue(authAtom);
  const { selectedDiscussion } = useDiscussions();
  const { t } = useTranslation('chat');

  const formattedDate = formatDate(createdDate, { format: 'HH:mm' }).replace(
    ':',
    'h'
  );

  const readIds = selectedDiscussion?.attributes.read;
  const receivedIds = selectedDiscussion?.attributes.received;

  const filteredReadParticipants = selectedDiscussion?.relationships.participants.data.filter(
    (participant) => readIds?.includes(participant.attributes._id as number) 
    && participant.attributes._id !== user?.attributes._id
  ) || [];

  const filteredReceivedParticipants = selectedDiscussion?.relationships.participants.data.filter(
    (participant) => receivedIds?.includes(participant.attributes._id as number) 
    && participant.attributes._id !== user?.attributes._id
  ) || [];

  const isReadBySomeone = filteredReadParticipants?.length > 0;

  const isReceivedBySomeone = filteredReceivedParticipants?.length > 0;

  const isReadByEveryone =
    selectedDiscussion?.attributes.read?.length ===
    selectedDiscussion?.relationships.participants?.data.length;

  return (
    <div className='flex flex-row justify-end items-center' 
      title={isReadByEveryone ? t('message_receiver.read_by_all') : isReadBySomeone ? t('message_receiver.read_by', {
        participants: filteredReadParticipants?.map(el => el.attributes.fullName)
      }): isReceivedBySomeone ? t('message_receiver.received_by', {
        participants: filteredReceivedParticipants?.map(el => el.attributes.fullName)
      }) : t('message_receiver.sent')}>
      <p
        className={`text-right text-[10px] !text-gray-300 mr-[2px] ${className}`}
      >
        {formattedDate}
      </p>
      {isMyMessage && !loading && <Icon
        icon={isReadBySomeone ? 'readNotification' : isReceivedBySomeone ? 'check' : 'inbox'}
        color={isReadByEveryone ? '#99ee83' : '#97a2ad'}
        
      />}
      {loading && (
        <p
          className={`flex flex-row-reverse text-right text-xs text-gray-500 ${className}`}
        >
          <SpinnerLoader className='w-3 h-3' />
        </p>
      )}
    </div>
  );
};

export const MessagesDateIndicator = ({
  prev,
  message,
}: {
  prev: Message;
  message: Message;
}) => {
  const today = formatDate(new Date(), { format: 'DD MMMM yyyy' });
  const actualDate = formatDate(message.attributes.createdDate, {
    format: 'DD MMMM yyyy',
  });

  const DateComponent = ({ date }: { date: string }) => (
    <div className='flex flex-row items-center mt-[-8px] mb-1'>
      <Separator />
      <p className='px-3 text-xs text-gray-600' style={{ textWrap: 'nowrap' }}>
        {date}
      </p>
      <Separator />
    </div>
  );

  if (actualDate === today && !prev) {
    return <DateComponent date={"Aujourd'hui"} />;
  }

  if (!prev) {
    return (
      <DateComponent
        date={
          formatDate(actualDate, { format: 'yyyy' }) ===
            formatDate(today, { format: 'yyyy' })
            ? formatDate(message?.attributes?.createdDate, {
              format: 'DD MMMM',
            })
            : actualDate
        }
      />
    );
  }

  const prevDate = formatDate(prev?.attributes?.createdDate, {
    format: 'DD MMMM yyyy',
  });

  if (actualDate === today && prevDate !== actualDate) {
    return <DateComponent date={"Aujourd'hui"} />;
  }
  if (prevDate !== actualDate) {
    return (
      <DateComponent
        date={
          formatDate(actualDate, { format: 'yyyy' }) ===
            formatDate(today, { format: 'yyyy' })
            ? formatDate(message?.attributes?.createdDate, {
              format: 'DD MMMM',
            })
            : actualDate
        }
      />
    );
  }
  return <></>;
};

export const MessageArrow = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 14px 10px;
  border-color: transparent transparent
    ${(props: { isMyMessage: boolean }) =>
    props.isMyMessage ? '#333c9d' : '#f3f4f5'}
    transparent;
  position: absolute;
  bottom: -7px;
  transform: translateY(-50%);
  right: ${(props: { isMyMessage: boolean }) =>
    props.isMyMessage ? '-6px' : ''};
  left: ${(props: { isMyMessage: boolean }) =>
    props.isMyMessage ? '' : '3px'};
  margin-left: ${(props: { isMyMessage: boolean }) =>
    props.isMyMessage ? '0' : '-12px'};
`;

export const MessagesLoaders = () => {
  const messages = Array.from(Array(5).keys());
  return (
    <div>
      {messages.map((el) => (
        <div
          key={`loading-messages-${el}`}
          className={`flex mx-4 my-2 ${el % 2 === 0 ? 'flex-row-reverse' : 'flex-row'
          }`}
        >
          <div className='inline-flex relative flex-col p-2 w-[100px] h-[50px] bg-[#f3f4f5] rounded-2xl' />
        </div>
      ))}
    </div>
  );
};
