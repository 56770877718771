import { LegacyMe } from '@/packages/types';
import { MeUser } from '@/packages/back-end/user';

export type ExtractPlateformLogoType = (user: LegacyMe) => { src: string; alt: string };
export const extractPlateformLogo:ExtractPlateformLogoType = (user) => {
  const { institutions } = user;
  const current = institutions.filter((institution) => institution.current);

  const logoProps = {
    src: '',
    alt: ''
  };

  if (!current.length) {
    return logoProps;
  }

  const institution = current[0];
  const { logo, name } = institution;

  return {
    src: logo,
    alt: name
  };
};


const FALLBACK = 'fr-FR';

export const getLocale = (user : MeUser | undefined) : string => {

  if(user?.attributes.locale) {
    return user.attributes.locale;
  }

  return FALLBACK;

};