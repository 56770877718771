import { Entity, Item, Relationship } from '@/packages/back-end/jsonapi';
import User from '@/packages/back-end/user';
import { buildUrl } from '@/utils/Api';


export enum TYPE {
  MERGE_REQUEST = 'merge_request',
  PATIENT_SHARED_TO_MEDIC = 'shared_to_medic',
  PATIENT_SHARED_TO_NURSE = 'shared_to_nurse',
  PATIENT_SHARED_TO_INSTITUTION = 'shared_to_institution',
  LINK_NURSE_TO_MEDIC = 'link_nurse_to_medic',
  LINK_NURSE_TO_NURSE = 'link_nurse_to_nurse',
  LINK_MEDIC_TO_MEDIC = 'link_medic_to_medic',
}

export enum STATUS {
  PENDING = 1,
  DONE = 2,
  CANCELED = 3,
  REVERTED = 4,
  DUPLICATE = 5,
  ACKNOWLEDGED = 6,
  HANDLED = 7
}

type Task = Entity & {
  attributes: {
    status: STATUS
    _type: TYPE
    createdDate: string
  },
  relationships: {
    subject?: Item<User|Relationship>
    owner?: Item<User|Relationship>
  }
}



export default Task;


export const POST_COLLECTION = () => buildUrl('tasks');