import React, { FC, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import cls from 'classnames';
import get from 'lodash/get';

import { HeaderActionsType } from '@ui/molecules/header/actions/HeaderActions';

import PortalOverlay from '@ui/atoms/portal-overlay/PortalOverlay';

import zindex from '@/utils/zindex';

import useDrawerManager, { EVENTS, DataEventType } from './useDrawerManager';
import { ContainerPropsType, DrawerSizeType } from './types';
import { Badges } from '@/packages/types';
import RightBar from '@/app/structure/menu/RightBar';
import useViewport from '@/utils/hooks/useViewport';

const ANITATION_TIME = 500;
const EXTRA_SIZE = 56;

export const sizes: DrawerSizeType = {
  small: 400,
  basic: 500,
  medium: 600,
  extra_large: 800,
  large: 900
};

export const realSizes: DrawerSizeType= {
  small: sizes.small + EXTRA_SIZE,
  medium: sizes.medium + EXTRA_SIZE,
  basic: sizes.basic + EXTRA_SIZE,
  extra_large: sizes.extra_large + EXTRA_SIZE,
  large: sizes.large + EXTRA_SIZE,
};

const Container = styled.div<ContainerPropsType>`
  ${({ size }) => size ? 'width:' + size + 'px' : ''};
    position: ${({ size }) => size ? 'absolute' : 'fixed'};
  ${({ preventAnimation }) => !preventAnimation && `transition: width ${ANITATION_TIME}ms ease-in-out 0s;`}
`;

export type DrawerManagerType = {
  overlayClassname?: string;
  containerClassname?: string;
  hideRightBar?: boolean;
  user: HeaderActionsType['user'],
  badges?: Badges
}



const DrawerManager: FC<DrawerManagerType> = ({
  overlayClassname,
  containerClassname,
  hideRightBar,
  ...props
}) => {
  const {
    closeDrawer,
    onDrawerOpened,
    onDrawerClosed
  } = useDrawerManager();

  const { xs, sm, md, width: viewportWidth } = useViewport();

  const [isOpened, setOpened] = useState(false);
  const [data, setData] = useState<DataEventType | null>(null);
  const { content, overlayClickClose = true, size = 'small', hideBar, onClickOverlay } = data || {};

  useEffect(() => {
    const updateData = (e: Event) => {
      const data = get(e, 'detail.data') as Partial<DataEventType>;
      setData((oldData) => {
        if (!oldData) {
          return oldData;
        }
        return { ...oldData, sidebarWidth: data?.sidebarWidth };
      });
    };

    const openHandler = (e: Event) => {
      onDrawerOpened({
        e,
        setData,
        eventType: EVENTS.openDrawer
      });
    };

    const closeHandler = () => {
      onDrawerClosed({ setData });
      setOpened(false);
    };

    // Add event listeners
    document.addEventListener(EVENTS.updateData, updateData);
    document.addEventListener(EVENTS.openDrawer, openHandler);
    document.addEventListener(EVENTS.closeDrawer, closeHandler);

    // Cleanup event listeners
    return () => {
      document.removeEventListener(EVENTS.updateData, updateData);
      document.removeEventListener(EVENTS.openDrawer, openHandler);
      document.removeEventListener(EVENTS.closeDrawer, closeHandler);
    };
  }, [onDrawerOpened, onDrawerClosed]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (data) {
      // wait for animation finished
      timeoutId = setTimeout(() => setOpened(true), ANITATION_TIME);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [data]);

  const Drawer: React.ReactNode[] = useMemo(() => {
    if (!content) {
      return [];
    }

    return [
      <div id='drawer-content' key='drawer-content'
        className='w-inherit'>
        <PortalOverlay
          className={overlayClassname}
          onClick={() => {
            if (overlayClickClose) {
              closeDrawer();
              onClickOverlay?.();
            }
          }}
        />
        {content}
      </div>
    ];
  }, [overlayClassname, content, overlayClickClose, closeDrawer, onClickOverlay]);

  let isFullSize = xs;
  if ((sm || md) && ['extra_large', 'large'].includes(size)) {
    isFullSize = true;
  }
  const width =
    data && size
      ? isFullSize
        ? viewportWidth
        : get(sizes, size) + EXTRA_SIZE + (data.sidebarWidth || 0)
      : null;

  const containerCls = cls(
    'fixed top-0 right-0 bottom-0 w-0 sm:min-w-[56px] border-l border-gray-50 max-w-[100%] bg-white flex',
    zindex.drawer,
    containerClassname
  );


  const hiddenBar = hideBar !== undefined ? hideBar : size !== 'small';

  const rightContainerCls = cls(
    'hidden sm:flex w-[56px] h-screen',
    Drawer.length && hiddenBar ? 'sm:hidden' : ''
  );

  return (
    <Container
      className={containerCls}
      id='drawer'
      size={width ? Math.min(width,viewportWidth) : null}
      preventAnimation={isOpened}
    >

      {!hideRightBar && (
        <div data-test-id='rightbarContainer' className={rightContainerCls}>
          <RightBar {...props} />
        </div>
      )}
      {Drawer}
    </Container>
  );
};

DrawerManager.displayName = 'DrawerManager';

export default DrawerManager;
