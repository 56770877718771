import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import {
  APPLE_CALENDAR,
  GOOGLE_CALENDAR,
  OUTLOOK_CALENDAR,
} from '@/packages/back-end/event';
import { authAtom } from '@/services/store/store';

import RetractableItem from '@ui/molecules/retractable-item/RetractableItem';
import LinkButton from '@ui/atoms/texts/link-button/LinkButton';
import Dropdown from '@ui/molecules/dropdown/Dropdown';

import DoctorInformationCard from './DoctorInformationCard';
import { ConfirmMeetingStepData } from './ConfirmMeetingStep';
import Alert from '@ui/atoms/alerts/Alert';
import { buildPatientProfileUrl } from '@/utils/Legacy';
import { useBookMeeting } from '../BookMeetingContext';
import { getLocale } from '@/app/structure/app/utils';

type ReviewMeetingStepProps = {
  stepNumber: number;
  open: boolean;
  onToggle: (open: boolean) => void;
  confirmMeetingStepData: ConfirmMeetingStepData;
};

const ReviewMeetingStep: FC<ReviewMeetingStepProps> = ({
  stepNumber,
  open,
  onToggle,
  confirmMeetingStepData,
}) => {
  const { profileDoctor,consultationLocation } = useBookMeeting();

  const { user: meUser } = useRecoilValue(authAtom);
  const { event } = confirmMeetingStepData;

  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer',
  });

  const { t: defaultT } = useTranslation();



  const syncItems = useMemo(
    () => [
      {
        children: t('review_meeting_step.google_calendar'),
        onClick: () => window.open(GOOGLE_CALENDAR(event,defaultT), '_blank')?.focus(),
      },
      {
        children: t('review_meeting_step.apple_calendar'),
        onClick: () => {
          const link = document.createElement('a');
          link.href = APPLE_CALENDAR(event,defaultT);
          link.download = `event-${event?.attributes?._id}.ics`;
          link.click();
        },
      },
      {
        children: t('review_meeting_step.outlook_calendar'),
        onClick: () => window.open(OUTLOOK_CALENDAR(event,defaultT), '_blank')?.focus(),
      },
    ],
    [event,defaultT, t]
  );

  const dateBegin = new Date(event?.attributes?.dateBegin as string);

  return (
    <RetractableItem
      withSeparator={false}
      open={open}
      retractableType='hidden'
      title={`${t('step')} ${stepNumber} : ${t('review_meeting_step.title')}`}
      onToggle={onToggle}
      wrapperTestId='review_meeting_step'
    >
      <div className='flex flex-col gap-4 w-full'>

        <Alert color='success' containerClass='flex flex-col gap-2' >
          <strong
            className='font-semibold'
            data-testid='review_meeting_step.success_alert.title'
          >
            {t('review_meeting_step.alert.title',{
              date: dateBegin.toLocaleDateString(getLocale(meUser),{
                month: 'long',
                day: '2-digit',
                weekday: 'long',
              }),
              hour: dateBegin.toLocaleTimeString(getLocale(meUser),{
                hour: '2-digit',
                minute: '2-digit',
              })
            })}
          </strong>
          <span>{t('review_meeting_step.alert.subtitle')}</span>
        </Alert>

        <DoctorInformationCard
          consultationLocation={consultationLocation}
          profileDoctor={profileDoctor}
        />

        <div className='flex gap-4'>
          <Dropdown
            popperClassName='z-[9999]'
            title={t('review_meeting_step.sync_to_my_calendar')}
            placement='top-end'
            items={syncItems}
          >
            {t('review_meeting_step.sync_to_my_calendar')}
          </Dropdown>
          <LinkButton
            className='w-full'
            color='primary'
            target='_black'
            href={buildPatientProfileUrl(meUser?.id as string)}
            data-testid='review_meeting_step.complete'
          >
            {t('review_meeting_step.complete')}
          </LinkButton>
        </div>
      </div>
    </RetractableItem>
  );
};

export default ReviewMeetingStep;
