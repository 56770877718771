import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { API, URLS } from '@/auth/constants';
import TextField from 'instamed-styleguide/ui/atoms/fields/text/TextField';
import { Control, useForm } from 'react-hook-form';
import Button from 'instamed-styleguide/ui/atoms/buttons/button/Button';
import ConstantSelector from '@/components/form/fields/constant-selector';
import DateOfBirthField from '@/components/form/fields/date-of-birth/DateOfBirthField';
import P from 'instamed-styleguide/ui/atoms/texts/base/P';
import CGUAgreed from '@/components/cgu-agreed/CGUAgreed';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import useRequest, { ResponseError } from '@/services/request/useRequest';
import { buildUrl } from '@/utils/Api';
import { formatDate } from '@/components/date-format/dateFormat';
import PasswordValidator from '@ui/molecules/password-validator/PasswordValidator';
import { getError } from '@/components/form/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { attachErrorsToFields } from '@/services/request/utils';
import { LoginArgs, LoginResponse } from '@/auth/hooks/useLogIn';
import useToast from '@/utils/hooks/useToast';
import get from 'lodash/get';
import PhoneField from '@/components/form/fields/phone/PhoneInput';
import usePasswordValidator from '@/utils/hooks/usePasswordValidator';
import styled from 'styled-components';

const PasswordWrapper = styled(PasswordValidator)`
    text-wrap: nowrap;
`;

/**
 * Create a new patient
 */
type FormDataType = {
  givenName: string;
  firstname: string;
  title: { label: string; value: string };
  lastname: string;
  email: string;
  repeatPassword: string;
  dateOfBirth: Date;
  CGUAgreed: boolean;
  phone: string;
  password: string;
  repeat_password: string;
};

const schema = yup.object({
  title: yup.object().required('field_required'),
  firstname: yup.string().required('field_required'),
  lastname: yup.string().required('field_required'),
  email: yup.string().required('field_required'),
  dateOfBirth: yup.date().required('field_required'),
  phone: yup.string().min(4, 'field_required').required('field_required'),
  password: yup.string().required('field_required'),
  CGUAgreed: yup.bool().oneOf([true], 'Field must be checked'),
  repeat_password: yup.string().required('field_required'),
});

interface CreatePatientFormProps {
  reloadingUserMe?: boolean;
  preventNavigate?: boolean;
  email?: string;
  onSuccess?: (loginResonse: LoginResponse) => void;
}

const CreatePatientForm: FC<CreatePatientFormProps> = ({
  reloadingUserMe,
  preventNavigate,
  email: emailProp,
  onSuccess,
}) => {
  const { t } = useTranslation('auth');
  const location = useLocation();
  const params = location.state;
  const {
    formState: { errors },
    register,
    resetField,
    control,
    setError,
    handleSubmit,
    getValues,
    trigger,
    watch,
  } = useForm<FormDataType>({
    resolver: yupResolver(schema),
  });

  const {
    strength,
    confirmStrength,
    register: registerPassword
  } = usePasswordValidator<FormDataType>({
    passwordName: 'password',
    confirmName: 'repeat_password',
    register,
    watch,
    getValues,
    trigger
  });

  const { t: errorT } = useTranslation('patient', {
    keyPrefix: 'form_errors',
  });

  const history = useHistory();
  const { addToast } = useToast();

  const { loading, data, error, fetchData: createPatient } = useJsonAPIRequest<ResponseError>({
    method: 'post',
    skip: true,
    url: buildUrl('signup/patients')
  });

  const keepRef = useRef({ onSuccess });
  keepRef.current.onSuccess = onSuccess;

  const {
    loading: loadingLogin,
    fetchData: login,
    data: logged,
    error: errorLogin
  } = useRequest<LoginResponse, LoginArgs>({
    url: API.LOGIN,
    skip: true,
    method: 'post',
    unauthenticated: true
  });

  const email = watch('email');
  const password = watch('password');

  const onSubmit = (data: FormDataType) => {
    const dateOfBirth = formatDate(data?.dateOfBirth, { format: 'YYYY-MM-DD' });

    createPatient({
      user: {
        firstName: data.firstname,
        title: data?.title?.value,
        lastName: data.lastname,
        givenName: data.givenName,
        email: emailProp || (params as { email: string })?.email,
        password: data?.password,
        repeatPassword: data?.['repeat_password'],
        dateOfBirth,
        CGUAgreed: data?.CGUAgreed,
        phones: [
          {
            number: data?.phone,
            extension: '33',
          },
        ],
      },
    });

  };

  useEffect(() => {
    attachErrorsToFields(setError, error, {
      'user.email': 'email',
      'user.dateOfBirth': 'dateOfBirth',
      'user.password': 'password',
      'user.phones[0].number': 'phone'
    });
  }, [error, setError]);

  useEffect(() => {
    const onValidateUser = async () => {
      const response = await login({ username: email, password });
      if (response) {
        keepRef.current.onSuccess?.(response as LoginResponse);
      }
    };

    if (data) {
      onValidateUser();
    }
  }, [data, email, history, login, password]);

  useEffect(() => {
    if (preventNavigate) {
      return;
    }

    if (logged) {
      history.push(URLS.CREATE_PATIENT_VALIDATE_NUMBER, {
        token: logged?.token,
        refreshToken: logged.refresh_token,
        email,
        password
      });
    } else if (errorLogin) {
      addToast('danger', get(errorLogin, 'hydra:description'));
    }
  }, [preventNavigate, addToast, email, errorLogin, history, logged, password]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      data-testid='form-signup-patient'
    >
      <div className='flex flex-row gap-4 mb-6'>
        <ConstantSelector
          name='title'
          label={t('form.create.patient.fields.civility')}
          error={getError('title', errors, errorT)}
          className='w-[50%]'
          control={control as unknown as Control}
          entity='user'
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          resetField={resetField}
          property='title'
          data-testid='selector-title'
        />
        <TextField
          label={t('form.create.patient.fields.firstname')}
          type='text'
          className='w-[50%]'
          error={getError('firstname', errors, errorT)}
          {...register('firstname',)}
        />
      </div>
      <div className='flex flex-row gap-4 mb-6'>
        <TextField
          label={t('form.create.patient.fields.given_name')}
          type='text'
          className='w-[50%]'
          {...register('givenName', { required: false })}
        />
        <TextField
          label={t('form.create.patient.fields.lastname')}
          type='text'
          className='w-[50%]'
          error={getError('lastname', errors, errorT)}
          {...register('lastname',)}
        />
      </div>
      <DateOfBirthField
        name='dateOfBirth'
        label={t('form.create.patient.fields.date_of_birth')}
        className='mb-6'
        error={getError('dateOfBirth', errors, errorT)}
        control={control as unknown as Control}
      />
      <div className='flex flex-row gap-4 mb-6'>
        <TextField
          label={t('form.create.patient.fields.email')}
          value={emailProp || (params as { email: string })?.email}
          contentEditable={false}
          disabled
          aria-disabled
          type='text'
          className='w-[50%]'
          helpTitle={t('form.create.patient.create_account.email_help')}
          error={getError('email', errors, errorT)}
          {...register('email',)}
        />
        <PhoneField
          label={t('form.create.patient.fields.phone')}
          containerClassName='w-[50%]'
          className='w-full'
          error={getError('phone', errors, errorT)}
          helpTitle={t('form.create.patient.create_account.phone_help')}
          {...register('phone', { required: true })}
        />
      </div>
      <div className='flex flex-row gap-4'>
        <PasswordWrapper
          label={t('form.create.patient.fields.password')}
          error={getError('password', errors, errorT)}
          className='w-[50%]'
          strength={strength}
          {...registerPassword('password')}
        />
        <PasswordWrapper
          label={t('form.create.patient.fields.repeat_password')}
          error={getError('repeat_password', errors, errorT)}
          className='w-[50%]'
          strength={confirmStrength}
          {...registerPassword('repeat_password')}
        />
      </div>
      <P className='mt-4'>
        {t('form.create.patient.fields.password_description')}
      </P>
      <CGUAgreed
        className='mt-4'
        name='CGUAgreed'
        error={getError('CGUAgreed', errors, errorT)}
        register={register as (name: string) => { name: string; }}
      />
      <Button
        saving={loading || loadingLogin || reloadingUserMe}
        className='mt-4 mb-8 w-full'
        type='submit'
        data-testid='submit'
        color='primary'
      >
        {t('form.create.patient.create_account.validate')}
      </Button>
    </form>
  );
};

export default CreatePatientForm;
